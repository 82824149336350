import React from "react"
import { Link, graphql } from "gatsby"
import "../styles/blog.css"
import Layout from "../components/layout"
import SEO from "../components/seo"
import moment from "moment"
import Img from "gatsby-image"
import { Heading, Box, Text } from "insites-ui"

const BlogPage = ({ data: { allContentfulBlogPost } }) => {
  const { edges } = allContentfulBlogPost
  return (
    <Layout>
      <SEO title="Blog" description="Australian investment property blog with real life case studies and guides on topics including purchase, negotiating tactics, granny flats and buying at a discount."/>
      <section className={"text-page-section blog-section"}>
        <div className={"container"}>
          <div className={"row"}>
            <div className={"col-lg-12 px-0"}>
              <Heading>Blog</Heading>
              <div className={"row"}>
                {edges.map(post => (
                  <div
                    className="col-12 col-sm-8 col-md-6 col-lg-4 py-3  "
                    key={post.node.id}
                  >
                    <Link
                      to={`/blog/${post.node.slug}`}
                      style={{ textDecoration: "none" }}
                      className="blog-card-link"
                    >
                      <Box
                        backgroundColor="#ffffff"
                        boxShadow="0 0.5rem 1rem rgba(0, 0, 0, 0.05)"
                        borderRadius="0.5rem"
                        padding="2rem"
                        height="100%"
                      >
                        <Img
                          sizes={post.node.heroImage.sizes}
                          className="card-img-top"
                          alt={post.node.heroImage.title}
                        />

                        <Heading as="h4" size="md">
                          {post.node.title}
                        </Heading>

                        <Text>{post.node.description.description}</Text>

                        <span className="publish-date">
                          {moment(post.node.publishDate).format("DD MMM YYYY")}
                        </span>
                      </Box>
                    </Link>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default BlogPage
export const pageQuery = graphql`
  query {
    allContentfulBlogPost(sort: { fields: [publishDate], order: DESC }) {
      edges {
        node {
          id
          title
          slug
          description {
            description
          }
          author {
            name
          }
          heroImage {
            sizes(maxWidth: 800) {
              ...GatsbyContentfulSizes
            }
            title
          }
          publishDate
        }
      }
    }
  }
`
